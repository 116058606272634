import React from 'react';
import { graphql, Link } from 'gatsby';
import { TimelineToggle, Timeline, SiteMetadata, CategoryMenu, GoogleAds, TypeButtons, AdvertCards } from '../components';
import { Layout } from '../layouts/Layout';
import { isRecent } from '../helper';

export default ({ data, pageContext: { title, category, previousPagePath, nextPagePath }, ...props }) => {
  const cardAdverts = AdvertCards(4);

  return (
    <Layout category={category || title} title={`${category || 'All'} Streamings Timeline View`}>
      <SiteMetadata
        title={category}
        description={`Timeline view for ${category} streamings that are being offered online for free, or for a small donation fee, during the COVID-19 pandemic`}
        path={props.location.pathname}
      />
      <div className="flex flex-row justify-center items-center w-full lg:justify-between">
        <TimelineToggle category={category} />
        <CategoryMenu />
      </div>
      <div className="pt-8 pb-2 w-full justify-center items-center flex flex-col self-center text-center">
        <h2 className="font-semibold text-3xl text-indigo-900 w-full lg:w-3/5 pb-2">
          Timeline View
        </h2>
        <h3 className="font-base text-base text-gray-600 leading-snug w-full lg:w-3/5">
          {`This is a timeline view for ${category ? category.toLowerCase() : ''} streamings available from last week, order by their start date.`}
          <br/>
          Streamings without a start date will not be shown, please refer to the normal grid view.
        </h3>
      </div>
      <Timeline nodes={data.items.nodes.filter(node => !isRecent(node.data.start))} Advert={cardAdverts} />
      <div className="container pt-4 pb-12 overflow-hidden justify-center align-center flex leading-tight text-white">
        {previousPagePath && <Link to={previousPagePath} className="inline-block rounded-lg px-4 py-3 bg-indigo-600 hover:bg-indigo-500 font-semibold shadow-lg mx-2">
          « Previous
        </Link>}
        {nextPagePath && <Link to={nextPagePath} className="inline-block rounded-lg px-4 py-3 bg-indigo-600 hover:bg-indigo-500 font-semibold shadow-lg mx-2">
          Next »
        </Link>}
      </div>
      <TypeButtons title="You might also be interested in..."></TypeButtons>
      <GoogleAds slotId="9917897326"/>
    </Layout>
  );
};



export const pageQuery = graphql`
  query PaginatedStreamingCalendarPageQuery($tableName: String!, $category: [String], $series: [String], $expiredArray: [Int] = [0, 1], $skip: Int!, $limit: Int!) {
    items: allAirtable(
      filter: { table: { eq: $tableName },
                data: { tags: { in: $category },
                start: { ne: null },
                expired: { in: $expiredArray }
                series: { in: $series } } }
      sort: { fields: data___start, order: ASC }
      skip: $skip
      limit: $limit
    ) {
      nodes {
        data {
          image {
            ...CardImageFragment
          }
          name
          slug
          summary
          start
          expiry
          expired
        }
      }
    }
  }
`;
